import Icon from './Icon';
import React from 'react';
import cl from '../utility/className';

export default function Button(props) {
  const {children, className, icon, text, type, ...rest} = props;
  return (
    <button
      className={cl(className, {'button--icon': icon != null})}
      title={icon ? text : null}
      type={type || 'button'}
      {...rest}>
      {icon && <Icon iconName={icon} />}
      {!icon && <span>{text}</span>}
      {children}
    </button>
  );
}
