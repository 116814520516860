import {DivIcon, DomUtil, Marker} from 'leaflet';

const defaultIcon = new DivIcon({className: 'marker--bus', iconSize: null});

export default Marker.extend({
  options: {
    icon: defaultIcon,
    interactive: false,
    rotation: 0
  },
  initialize: function (bus) {
    Marker.prototype.initialize.call(this, bus, {rotation: bus.course});
  },
  includes: {
    _setPos: function (pos) {
      Marker.prototype._setPos.call(this, pos);

      this._icon.style[DomUtil.TRANSFORM + 'Origin'] = '50% 50%';
      this._icon.style[DomUtil.TRANSFORM] += ` rotateZ(${this.options.rotation}deg)`;
    }
  }
});
