import Form from '../components/Form';
import React, {useState} from 'react';
import ToggleField from '../components/ToggleField';

export default function SettingsForm(props) {
  const [error, setError] = useState(null);
  const {deviceType, hasPermission, language, reducer, settings} = props;

  if (!hasPermission) {
    return (
      <>
        <h2 className="header--group block--small">
          {language.translate('notificationPermission')}
        </h2>
        <p>
          {language.translate(
            deviceType === 'web' ? 'notificationPermissionBrowser' : 'notificationPermissionApp'
          )}
        </p>
      </>
    );
  }

  return (
    <Form
      name="settingsForm"
      item={settings}
      language={language}
      onFieldChange={(name, value) => {
        reducer.saveSettings(name, value).then(error => setError(error));
      }}>
      <h2 className="header--group">{language.translate('notificationSettingsHeader')}</h2>
      <ToggleField id="bus_moving" />
      <ToggleField id="stop_change" />
      <ToggleField id="bus_arrived" />
      <ToggleField id="pickup_swipe" />
      <ToggleField id="dropoff_swipe" />
      {error && <p className="error">{error}</p>}
    </Form>
  );
}
