import Icon from './Icon';
import React from 'react';
import cl from '../utility/className';

function handleClick(e) {
  e.preventDefault();
  location.replace(e.currentTarget.href);
}

export default function NavButton(props) {
  const {hasNotifications, icon, name, title} = props;
  const path = '#' + name;

  return (
    <a
      className={cl('nav__button', {
        notify: hasNotifications,
        selected: location.hash === path
      })}
      href={path}
      onClick={handleClick}>
      <Icon iconName={icon || name} />
      {title}
    </a>
  );
}
