import Field from '../hoc/field';
import Icon from './Icon';
import React from 'react';
import cl from '../utility/className';

function ButtonGroup(props) {
  const {className, data, id, onChange, translate, value} = props;
  return (
    <div className={cl('radio-group', className)}>
      {data.map(button => {
        const {icon} = button;
        const buttonId = button.id || button;
        const title = translate(buttonId);
        const fieldId = id + '_' + buttonId;
        const buttonValue = button.value != null ? button.value : buttonId;

        return (
          <div key={fieldId} className="radio-button">
            <input
              id={fieldId}
              name={id}
              aria-label={icon ? title : null}
              type="radio"
              checked={value === buttonValue}
              onChange={() => onChange(id, buttonValue)}
            />
            <label htmlFor={fieldId}>
              {icon && <Icon iconName={icon} />} {title}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default Field(ButtonGroup, {hasTranslations: true, useFieldset: true});
