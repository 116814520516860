import {getValue} from './util';
import en from '../intl/en';

function getCardinalRulesSelector(locale) {
  if (window.Intl.PluralRules) {
    return new window.Intl.PluralRules(locale, {type: 'cardinal'});
  }

  // Safari doesn't support Intl.PluralRules, so here's a simple English one
  return {select: number => (number === 1 ? 'one' : 'other')};
}

export default class Language {
  constructor() {
    this.locale = 'en-US';

    this.cardinalPluralRules = getCardinalRulesSelector(this.locale);
    this.dictionary = en;
  }

  getTranslator(...path) {
    const root = getValue(this.dictionary, ...path) || {};
    return (prop, ...args) => this.translateLine(root[prop], args);
  }

  translate(prop, ...args) {
    return this.translateLine(getValue(this.dictionary, prop), args);
  }

  translateLine(line, args) {
    let result = line || '';
    for (let i = 0; i < args.length; i++) {
      const value = args[i];
      result = result.replace(`{${i}}`, value != null ? value : '');
    }

    return result;
  }

  translatePlural(number, word) {
    const category = this.cardinalPluralRules.select(number);
    const terms = getValue(this.dictionary, 'pluralCardinalRules', word);

    if (terms) {
      return terms[category] || terms.other;
    }
  }
}
