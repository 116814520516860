import EventHistory from '../components/EventHistory';
import React from 'react';
import cl from '../utility/className';

export default function ActivityPage(props) {
  const {events, isLoading, isVisible, language} = props;
  return (
    <div aria-hidden={isVisible === false} className="page__body">
      <div className="page__content">
        {events.length ? (
          <EventHistory events={events} language={language} />
        ) : (
          <p className={cl({'loading-indicator': isLoading})}>
            {language.translate(isLoading ? 'loading' : 'noActivity')}
          </p>
        )}
      </div>
    </div>
  );
}
