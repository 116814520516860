import {isSameDay, renderDateTime, renderEventText} from '../utility/util';
import React from 'react';

export default function EventHistory(props) {
  const {events, language} = props;
  if (!events?.length) {
    return null;
  }

  let lastDay;
  const elements = [];

  events.forEach(event => {
    if (!isSameDay(event.event_time, lastDay)) {
      lastDay = event.event_time;
      elements.push(
        <h2 className="header--group" key={elements.length}>
          {renderDateTime(lastDay, 'day')}
        </h2>
      );
    }

    elements.push(
      <div className="event" key={elements.length}>
        {renderEventText(event, language)}
        <span className="event-time">{renderDateTime(event.event_time)}</span>
      </div>
    );
  });

  return <div className="list--events">{elements}</div>;
}
