import {getValue} from './utility/util';

function parseData(data, type) {
  if (data && type !== 'text') {
    try {
      return JSON.parse(data);
    } catch (e) {
      console.debug(e);
    }
  }

  return data;
}

function getRequester() {
  // POSTs need to go through a plugin to bypass CORS when on a device
  const http = getValue(window, 'cordova', 'plugin', 'http');
  if (http) {
    http.setDataSerializer('json');
    http.setHeader('Accept-Language', navigator.language);

    return {
      clearCookies: () => http.clearCookies(),
      fetch: function (url, options, type) {
        return new Promise(function (resolve, reject) {
          http.sendRequest(
            url,
            options,
            res => resolve(parseData(res.data, type)),
            e => reject(e)
          );
        });
      },
      post: function (url, data = {}, method, type) {
        return this.fetch(url, null, 'text').then(token => {
          const methodType = (method || 'post').toLowerCase();
          return this.fetch(url + '?_csrf=' + token, {data, method: methodType}, type);
        });
      }
    };
  }

  // Use standard fetch calls for web / local development
  return {
    clearCookies: () => {},
    fetch: function (url, options = {}, type) {
      options.credentials = 'include';
      return fetch(url, options).then(res => {
        if (!res.ok) {
          throw res;
        }

        return type === 'text' ? res.text() : res.json();
      });
    },
    post: function (url, body, method, type) {
      return this.fetch(url, {}, 'text').then(token => {
        const data = {
          body: JSON.stringify(body),
          headers: {'Content-Type': 'application/json'},
          method: method || 'POST'
        };

        return this.fetch(url + '?_csrf=' + token, data, type);
      });
    }
  };
}

export {getRequester};
