import Field from '../hoc/field';
import React from 'react';
import cl from '../utility/className';

function ToggleField(props) {
  const {className, id, label, onChange, value, ...rest} = props;
  return (
    <label className={cl('field toggle', className)}>
      <span className="toggle__label">{label}</span>
      <input
        checked={!!value}
        onChange={e => onChange(id, e.target.checked)}
        type="checkbox"
        {...rest}
      />
      <div className="toggle__switch" />
    </label>
  );
}

export default Field(ToggleField, {noContainer: true});
