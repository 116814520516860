import 'firebase/messaging';
import firebase from 'firebase/app';

let isRegistered, messaging, workerRegistration;

if (firebase.messaging.isSupported()) {
  firebase.initializeApp({
    apiKey: 'AIzaSyC7XgtDdbtwtWQGYniTZ5EQUCJX2zzK-_8',
    authDomain: 'bus-compass-785c0.firebaseapp.com',
    projectId: 'bus-compass-785c0',
    messagingSenderId: '243987254175',
    appId: '1:243987254175:web:b3bf3a2097b45ca9'
  });

  messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    'BM6orhQJ5F-6gwi91_Is3fOvB7PVzW0HajCiEsu5PAMvwh6fy2dUwBeOM6DWd0bdwJWnu8LbjbCS1o6tXCdHvX0'
  );
}

export default {
  hasPermission: function () {
    return Promise.resolve(Notification.permission === 'granted');
  },

  register: function (onDeviceToken, onNotification) {
    if (isRegistered || !messaging) {
      return;
    }

    let deviceToken;
    isRegistered = true;

    function registerWorker() {
      if (workerRegistration) {
        return Promise.resolve();
      }

      return navigator.serviceWorker
        .register('./serviceWorker.js')
        .then(registration => {
          workerRegistration = registration;
          messaging.useServiceWorker(registration);
          messaging.onMessage(payload => onNotification(payload.data));
          messaging.onTokenRefresh(refreshToken);
        })
        .catch(e => console.debug('Failed to register serviceWorker', e));
    }

    function refreshToken() {
      return messaging
        .getToken()
        .then(token => {
          if (token && deviceToken !== token) {
            onDeviceToken(token);
          }

          deviceToken = token;
          return token;
        })
        .catch(console.debug);
    }

    registerWorker().then(refreshToken);
  },

  unregister: function () {
    let promise;
    if (workerRegistration) {
      promise = workerRegistration.unregister();
      workerRegistration = null;
    }

    isRegistered = false;
    return promise || Promise.resolve();
  }
};
