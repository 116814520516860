import {DivIcon, LatLngBounds, Map, Marker, TileLayer, control} from 'leaflet';
import BusMarker from './busMarker';

export default class LeafletMap {
  constructor(id, center) {
    this.map = new Map(id, {
      attributionControl: false,
      bounceAtZoomLimits: false,
      boxZoom: false,
      center,
      dragging: false,
      markerZoomAnimation: false,
      zoom: 16,
      zoomControl: false,
      zoomSnap: 0.1
    });

    this.map
      .on('resize', () => this.showAll())
      .on('zoomend', () => this.onZoomEnd())
      .addControl(control.attribution({prefix: false}))
      .addLayer(
        new TileLayer('https://tile{s}.transportant.com/{z}/{x}/{y}.png', {
          attribution:
            '© <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a> contributors',
          maxNativeZoom: 18,
          maxZoom: 21,
          subdomains: '12',
          zoomDelta: 0.1
        })
      );
  }

  destroy() {
    this.map.remove();
  }

  createBus(bus) {
    return {
      data: bus,
      marker: new BusMarker(bus).addTo(this.map)
    };
  }

  createStopMarker(location) {
    const marker = new Marker(location, {
      icon: new DivIcon({
        className: 'marker--location',
        html:
          '<svg class="icon" aria-hidden="true" viewBox="0 0 16 28"><path d="M12 10c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zM16 10c0 0.953-0.109 1.937-0.516 2.797l-5.688 12.094c-0.328 0.688-1.047 1.109-1.797 1.109s-1.469-0.422-1.781-1.109l-5.703-12.094c-0.406-0.859-0.516-1.844-0.516-2.797 0-4.422 3.578-8 8-8s8 3.578 8 8z" /></svg>',
        iconAnchor: [18, 32],
        iconSize: [36, 36],
        interactive: false
      })
    }).addTo(this.map);

    return {data: location, marker};
  }

  onZoomEnd() {
    const container = this.map.getContainer();
    container.dataset.zoom = Math.round(this.map.getZoom());
  }

  setItem(prop, newItem) {
    const item = this[prop];
    if (item && item.marker) {
      item.marker.remove();
    }

    this[prop] = newItem;
  }

  setRoute(route) {
    const {bus, stop} = route || {};
    if (!this.map._loaded && stop) {
      this.map.setView(stop);
    }

    this.points = [bus, stop].filter(Boolean);
    this.setItem('bus', bus && this.createBus(bus));
    this.setItem('stop', stop && this.createStopMarker(stop));
    this.showAll();
  }

  showAll() {
    if (!this.map || !this.points || !this.points.length) {
      return;
    }

    const panOptions = {
      animate: true,
      duration: 0.3,
      easeLinearity: 0.5,
      paddingTopLeft: [20, 75],
      paddingBottomRight: [20, 20]
    };

    const currentZoom = this.map.getZoom();
    const newBounds = new LatLngBounds(this.points);

    // Using an internal method was by far the easiest way to compensate for
    // padding. Otherwise, we can't tell if the map bounds have changed.
    const {center, zoom} = this.map._getBoundsCenterZoom(newBounds, panOptions);

    const zoomDelta = Math.abs(zoom - currentZoom);
    if (zoomDelta < 0.1 && this.map.getCenter().equals(center, 0.0005)) {
      return;
    }

    if (zoomDelta) {
      this.map.flyTo(center, zoom, panOptions);
    } else {
      this.map.panTo(center, panOptions);
    }
  }

  showDefaultLocation() {
    if (!this.map || this.map._loaded) {
      return;
    }

    this.map.locate({enableHighAccuracy: true, setView: true});
  }
}
