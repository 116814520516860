import Icon from './Icon';
import React, {Children, cloneElement} from 'react';

export default function InlineInputField(props) {
  const {children, icon} = props;
  return (
    <div className="input--joined">
      {Children.map(children, child => cloneElement(child, {inline: true, usePlaceholder: true}))}
      <Icon iconName={icon} />
    </div>
  );
}
