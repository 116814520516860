import Button from '../components/Button';
import ButtonGroup from '../components/ButtonGroup';
import Form from '../components/Form';
import InlineInputField from '../components/InlineInputField';
import InputField from '../components/InputField';
import React, {Component} from 'react';
import Storage from '../utility/Storage';
import logoImage from '../images/logo.png';

function getError(props) {
  if (!props.isOnline) {
    return props.language.translate('offline');
  }

  return props.error;
}

export default class AuthPage extends Component {
  state = {};
  storage = new Storage();

  componentDidMount() {
    this.setState({contactType: this.storage.get('contactType') || 'email'});
  }

  componentDidUpdate(prevProps) {
    if (this.props.hash !== prevProps.hash && this.props.hash !== 'verify') {
      this.setState({passcode: '', showDirections: false});
    }
  }

  onFieldChange = (name, value) => {
    this.setState({[name]: value});
    if (name === 'contactType') {
      this.storage.set('contactType', value);
    }
  };

  onSubmit = e => {
    e.preventDefault();

    const {hash, reducer} = this.props;
    const {contactType, email, passcode, phone} = this.state;

    if (hash === 'verify') {
      reducer.verify(passcode);
    } else if (contactType === 'email') {
      reducer.register({email});
    } else {
      reducer.register({phone});
    }
  };

  render() {
    return (
      <main className="page--centered">
        <form className="content--centered" onSubmit={this.onSubmit}>
          <img className="block" src={logoImage} alt="Bus Compass" />
          {this.props.hash === 'verify' ? this.renderVerificationForm() : this.renderContactForm()}
        </form>
      </main>
    );
  }

  renderContactForm() {
    const {isWorking, language} = this.props;
    const error = getError(this.props);

    return (
      <Form
        item={this.state}
        language={language}
        name="loginForm"
        onFieldChange={this.onFieldChange}>
        <p className="block">{language.translate('registerNotice')}</p>
        <ButtonGroup
          data={[
            {id: 'email', icon: 'email'},
            {id: 'phoneNumber', icon: 'phone'}
          ]}
          id="contactType"
          inline
        />
        {this.state.contactType === 'email' ? (
          <InlineInputField icon="email">
            <InputField id="email" type="email" autoComplete="email" />
          </InlineInputField>
        ) : (
          <InlineInputField icon="phone">
            <InputField id="phone" type="tel" autoComplete="tel-national" mask="numeric" />
          </InlineInputField>
        )}
        {error && <p className="error">{error}</p>}
        <Button
          className="button--primary"
          disabled={isWorking}
          key="login"
          text={language.translate('login')}
          type="submit"
        />
      </Form>
    );
  }

  renderVerificationForm() {
    const {isWorking, language} = this.props;
    const {contactType, showDirections} = this.state;
    const error = getError(this.props);

    return (
      <Form
        item={this.state}
        language={language}
        name="loginForm"
        onFieldChange={this.onFieldChange}>
        <p className="block--small">
          {language.translate(contactType === 'email' ? 'verifyEmail' : 'verifyPhone')}
        </p>
        <InlineInputField icon="lock">
          <InputField id="passcode" type="tel" />
        </InlineInputField>
        {error && <p className="error">{error}</p>}
        <div className="flex-grid block--small">
          <Button
            className="button--primary"
            disabled={isWorking}
            key="login"
            text={language.translate('login')}
            type="submit"
          />
          <Button
            className="button--ghost"
            key="back"
            onClick={() => history.back()}
            text={language.translate('back')}
          />
        </div>
        {showDirections ? (
          <>
            <p className="block--small">{language.translate('verifyInfo1')}</p>
            <p>{language.translate('verifyInfo2')}</p>
          </>
        ) : (
          <Button
            className="button--ghost"
            key="moreInfo"
            onClick={() => this.setState({showDirections: true})}
            text={language.translate('codeNoteReceived')}
          />
        )}
      </Form>
    );
  }
}
