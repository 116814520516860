import NotificationList from '../components/NotificationList';
import React from 'react';
import StudentList from '../components/StudentList';
import cl from '../utility/className';

export default function OverviewPage(props) {
  const {
    ConnectionWarning,
    isLoading,
    isVisible,
    language,
    notifications,
    reducer,
    students
  } = props;

  return (
    <div aria-hidden={isVisible === false} className="page__body">
      <div className="page__content">
        <ConnectionWarning />
        <NotificationList
          className="block"
          language={language}
          notifications={notifications}
          reducer={reducer}
        />
        {students.length ? (
          <StudentList language={language} students={students} />
        ) : (
          <p className={cl({'loading-indicator': isLoading})}>
            {language.translate(isLoading ? 'loading' : 'noStudents')}
          </p>
        )}
      </div>
    </div>
  );
}
