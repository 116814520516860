import Button from '../components/Button';
import React from 'react';

export default function NotificationList(props) {
  const {className, language, notifications, reducer} = props;
  if (!notifications.length) {
    return null;
  }

  return (
    <>
      <h2 className="header--group block--xs">{language.translate('notificationsHeader')}</h2>
      <ul className={className}>
        {notifications.map(notification => {
          const {body, id, message} = notification;
          return (
            <li key={id} className="notification">
              <Button
                className="notification__button button--ghost"
                icon="close"
                onClick={() => reducer.onNotificationClosed(notification)}
                text={language.translate('close')}
              />
              <p>{message || body}</p>
            </li>
          );
        })}
      </ul>
    </>
  );
}
