import AppErrorPage from './pages/AppErrorPage';
import AppReducer from './AppReducer';
import AuthPage from './pages/AuthPage';
import DesktopShell from './pages/DesktopShell';
import Language from './utility/language';
import MobileShell from './pages/MobileShell';
import React, {Component} from 'react';
import cl from './utility/className';

const getUrlHash = () => window.location.hash.slice(1);
const isViewportMobile = () => window.innerWidth <= 768;

export default class App extends Component {
  constructor(props) {
    super(props);

    const {reducer, ...rest} = props;
    const language = new Language();

    const reducerOptions = {
      ...rest,
      language,
      onUpdate: result => this.setState(result)
    };

    const hash = getUrlHash();
    if (Number(hash)) {
      reducerOptions.id = hash;
      location.replace('#');
    } else if (hash === 'verify') {
      location.replace('');
    }

    this.state = {
      hash: getUrlHash(),
      isMobile: isViewportMobile(),
      isOnline: navigator.onLine !== false,
      language
    };

    this.reducer = reducer || new AppReducer(reducerOptions);
    window.addEventListener('hashchange', this.setLocationState, false);
    window.addEventListener('offline', this.onConnectionChange, false);
    window.addEventListener('online', this.onConnectionChange, false);
    window.addEventListener('resize', this.onWindowResize, false);
  }

  componentDidCatch() {
    this.setState({hasError: true});
  }

  componentDidUpdate(prevProps, prevState) {
    const {hash, isLoaded, isLoggedIn} = this.state;
    if (!isLoaded || (isLoggedIn === prevState.isLoggedIn && hash === prevState.hash)) {
      return;
    }

    const isInApp = hash && hash !== 'verify';
    if (isLoggedIn && !isInApp) {
      location.replace('#overview');
    } else if (!isLoggedIn && isInApp) {
      location.replace('#');
    }
  }

  onConnectionChange = () => this.setState({isOnline: navigator.onLine !== false});

  onWindowResize = () => {
    const isMobile = isViewportMobile();
    if (isMobile !== this.state.isMobile) {
      this.setState({isMobile});
    }
  };

  setLocationState = () => this.setState({error: null, hash: getUrlHash()});

  render() {
    const {hasError, isLoaded, isLoggedIn, isOnline, isStale, language, ...rest} = this.state;

    if (hasError) {
      return <AppErrorPage language={language} />;
    }

    if (!isLoaded) {
      return <div className="container--loading" />;
    }

    const pageProps = {isOnline, language, reducer: this.reducer, ...rest};
    if (!isLoggedIn) {
      return <AuthPage {...pageProps} />;
    }

    if (!isOnline || isStale) {
      pageProps.ConnectionWarning = props => (
        <p className={cl('warning', props.className)}>
          {language.translate(isOnline ? 'stale' : 'offline')}
        </p>
      );
    } else {
      pageProps.ConnectionWarning = () => null;
    }

    const AppComponent = pageProps.isMobile ? MobileShell : DesktopShell;
    return <AppComponent {...pageProps} />;
  }
}
