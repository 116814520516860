export default class Storage {
  constructor(storageSystem) {
    this.system = storageSystem || localStorage;
  }

  get(key, replacer) {
    try {
      const item = key && this.system.getItem(key);
      return item && JSON.parse(item, replacer);
    } catch (e) {
      console.debug(e);
    }
  }

  set(key, value, replacer) {
    try {
      if (key && value) {
        this.system.setItem(key, JSON.stringify(value, replacer));
      } else {
        this.system.removeItem(key);
      }
    } catch (e) {
      console.debug(e);
    }
  }
}
