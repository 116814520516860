import Field from '../hoc/field';
import React from 'react';

function SelectField(props) {
  const {className, data, id, label, name, onChange, translate, value = ''} = props;
  const emptyOption = translate('empty');

  return (
    <select
      aria-label={label}
      className={className}
      id={id}
      onChange={e => onChange(name, e.target.value)}
      name={name}
      value={value}>
      {emptyOption && <option>{emptyOption}</option>}
      {data.map(item => {
        const optionId = item.id || item;
        return (
          <option key={optionId} value={optionId}>
            {item.name || translate(optionId)}
          </option>
        );
      })}
    </select>
  );
}

export default Field(SelectField, {hasTranslations: true});
