import Field from '../hoc/field';
import React from 'react';

function cloneChildren(children, cloneField) {
  return React.Children.map(children, child => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (child.type.displayName === 'Field') {
      return cloneField(child);
    }

    if (!child.props.children) {
      return child;
    }

    return React.cloneElement(child, {
      children: cloneChildren(child.props.children, cloneField)
    });
  });
}

function hasValueChanged(value1, value2) {
  return value1 !== value2 && (value1 || value2);
}

export default function Form(props) {
  const {children, item, language, name, onFieldChange} = props;

  const onChange = (id, value) => {
    if (item && hasValueChanged(item[id], value)) {
      onFieldChange(id, value);
    }
  };

  return cloneChildren(children, field =>
    React.cloneElement(field, {
      language,
      onChange,
      translate: language.getTranslator(name),
      value: item && item[field.props.id]
    })
  );
}
