import React, {memo} from 'react';
import cl from '../utility/className';

const iconGeometry = {
  calendar: [
    'M19 3h-2v-1c0-0.6-0.4-1-1-1s-1 0.4-1 1v1h-6v-1c0-0.6-0.4-1-1-1s-1 0.4-1 1v1h-2c-1.7 0-3 1.3-3 3v14c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-14c0-1.7-1.3-3-3-3zM5 5h2v1c0 0.6 0.4 1 1 1s1-0.4 1-1v-1h6v1c0 0.6 0.4 1 1 1s1-0.4 1-1v-1h2c0.6 0 1 0.4 1 1v3h-16v-3c0-0.6 0.4-1 1-1zM19 21h-14c-0.6 0-1-0.4-1-1v-9h16v9c0 0.6-0.4 1-1 1z'
  ],
  close: [
    'M13.4 12l5.3-5.3c0.4-0.4 0.4-1 0-1.4s-1-0.4-1.4 0l-5.3 5.3-5.3-5.3c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l5.3 5.3-5.3 5.3c-0.4 0.4-0.4 1 0 1.4 0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3l5.3-5.3 5.3 5.3c0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3c0.4-0.4 0.4-1 0-1.4l-5.3-5.3z'
  ],
  email: [
    'M20 3h-16c-1.7 0-3 1.3-3 3v12c0 1.7 1.3 3 3 3h16c1.7 0 3-1.3 3-3v-12c0-1.7-1.3-3-3-3zM4 5h16c0.4 0 0.7 0.2 0.9 0.6l-8.9 6.2-8.9-6.2c0.2-0.4 0.5-0.6 0.9-0.6zM20 19h-16c-0.6 0-1-0.4-1-1v-10.1l8.4 5.9c0.2 0.1 0.4 0.2 0.6 0.2s0.4-0.1 0.6-0.2l8.4-5.9v10.1c0 0.6-0.4 1-1 1z'
  ],
  exclamation: [
    'M8 19.5v3.5c0 0.547-0.453 1-1 1h-4c-0.547 0-1-0.453-1-1v-3.5c0-0.547 0.453-1 1-1h4c0.547 0 1 0.453 1 1zM8.469 3l-0.438 12c-0.016 0.547-0.484 1-1.031 1h-4c-0.547 0-1.016-0.453-1.031-1l-0.438-12c-0.016-0.547 0.422-1 0.969-1h5c0.547 0 0.984 0.453 0.969 1z'
  ],
  lock: [
    'M19 10h-1v-3c0-3.3-2.7-6-6-6s-6 2.7-6 6v3h-1c-1.7 0-3 1.3-3 3v7c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-7c0-1.7-1.3-3-3-3zM8 7c0-2.2 1.8-4 4-4s4 1.8 4 4v3h-8v-3zM20 20c0 0.6-0.4 1-1 1h-14c-0.6 0-1-0.4-1-1v-7c0-0.6 0.4-1 1-1h14c0.6 0 1 0.4 1 1v7z'
  ],
  map: [
    'M23.5 1.1c-0.3-0.2-0.7-0.2-1 0l-6.5 3.8-7.6-3.8c0 0 0 0 0 0s0 0-0.1 0c-0.1-0.1-0.2-0.1-0.3-0.1 0 0 0 0 0 0s0 0 0 0c-0.1 0-0.2 0-0.4 0.1 0 0 0 0-0.1 0 0 0-0.1 0-0.1 0l-7 4c-0.2 0.2-0.4 0.5-0.4 0.9v16c0 0.4 0.2 0.7 0.5 0.9s0.7 0.2 1 0l6.5-3.8 7.5 3.8c0 0 0 0 0 0 0.2 0.1 0.3 0.1 0.5 0.1 0.1 0 0.2 0 0.3-0.1 0 0 0.1 0 0.1 0s0 0 0.1 0l7-4c0.3-0.2 0.5-0.5 0.5-0.9v-16c0-0.4-0.2-0.7-0.5-0.9zM9 3.6l6 3v13.8l-6-3v-13.8zM2 6.6l5-2.9v13.7l-5 2.9v-13.7zM22 17.4l-5 2.9v-13.7l5-2.9v13.7z'
  ],
  people: [
    'M13 14h-8c-2.8 0-5 2.2-5 5v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-1.7 1.3-3 3-3h8c1.7 0 3 1.3 3 3v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0-2.8-2.2-5-5-5z',
    'M9 12c2.8 0 5-2.2 5-5s-2.2-5-5-5-5 2.2-5 5 2.2 5 5 5zM9 4c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z',
    'M20.2 14.2c-0.5-0.1-1.1 0.2-1.2 0.7s0.2 1.1 0.7 1.2c1.3 0.3 2.2 1.5 2.2 2.9v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2c0.1-2.3-1.4-4.3-3.7-4.8z',
    'M16.2 2.2c-0.5-0.2-1 0.1-1.2 0.7-0.1 0.5 0.2 1.1 0.7 1.2 1.6 0.4 2.6 2 2.2 3.7-0.3 1.1-1.1 1.9-2.2 2.2-0.5 0.1-0.9 0.7-0.7 1.2 0.1 0.5 0.5 0.8 1 0.8 0.1 0 0.2 0 0.2 0 1.8-0.5 3.2-1.8 3.6-3.6 0.7-2.8-0.9-5.6-3.6-6.2z'
  ],
  phone: [
    'M20.4 13.9c-0.9-0.1-1.8-0.3-2.6-0.6-1.1-0.4-2.3-0.1-3.2 0.7l-0.7 0.7c-1.8-1.2-3.4-2.7-4.6-4.6l0.7-0.7c0.8-0.8 1.1-2.1 0.7-3.2-0.3-0.8-0.5-1.7-0.6-2.6-0.2-1.5-1.5-2.6-3-2.6 0 0 0 0 0 0h-3c-0.1 0-0.2 0-0.3 0-0.8 0.1-1.5 0.5-2 1.1s-0.7 1.4-0.7 2.2c0.3 3.2 1.5 6.4 3.2 9.1 1.6 2.5 3.8 4.7 6.3 6.3 2.7 1.8 5.9 2.9 9.1 3.2 0.1 0 0.2 0 0.3 0 0 0 0 0 0 0 0.8 0 1.6-0.3 2.1-0.9s0.9-1.3 0.9-2.1v-3c0-1.5-1.1-2.8-2.6-3zM21 16.9v3c0 0.3-0.1 0.5-0.3 0.7s-0.4 0.3-0.8 0.3c-2.9-0.3-5.7-1.3-8.2-2.9-2.3-1.4-4.2-3.4-5.7-5.7-1.6-2.5-2.6-5.3-2.9-8.2 0-0.3 0.1-0.5 0.2-0.7 0.2-0.2 0.5-0.4 0.8-0.4h3c0 0 0 0 0 0 0.5 0 0.9 0.4 1 0.9 0.1 1 0.4 2.1 0.8 3 0.1 0.4 0 0.8-0.2 1.1l-1.3 1.2c-0.3 0.3-0.4 0.8-0.2 1.2 1.5 2.7 3.7 4.9 6.4 6.4 0.4 0.2 0.9 0.2 1.2-0.2l1.3-1.3c0.3-0.3 0.7-0.4 1.1-0.2 1 0.4 2 0.6 3 0.8 0.4 0.1 0.8 0.5 0.8 1 0 0 0 0 0 0z'
  ],
  settings: [
    'M12 8c-2.2 0-4 1.8-4 4s1.8 4 4 4c2.2 0 4-1.8 4-4s-1.8-4-4-4zM12 14c-1.1 0-2-0.9-2-2s0.9-2 2-2c1.1 0 2 0.9 2 2s-0.9 2-2 2z',
    'M20.3 15.4c0.1-0.2 0.3-0.4 0.7-0.4 1.7 0 3-1.3 3-3s-1.3-3-3-3h-0.2c-0.2 0-0.4-0.1-0.5-0.3 0-0.1 0-0.1-0.1-0.2-0.1-0.2-0.1-0.5 0.2-0.8 1.2-1.2 1.2-3.1 0-4.2v0c0 0 0 0 0 0-0.6-0.6-1.3-0.9-2.1-0.9 0 0 0 0 0 0-0.8 0-1.6 0.3-2.2 0.9-0.2 0.2-0.5 0.2-0.7 0.1-0.2 0-0.4-0.3-0.4-0.6 0-1.7-1.3-3-3-3s-3 1.3-3 3v0.2c0 0.2-0.1 0.4-0.3 0.5-0.1 0-0.1 0-0.2 0.1-0.2 0.1-0.5 0-0.8-0.2-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0.1 4.3 0.2 0.2 0.2 0.5 0.1 0.8-0.1 0.2-0.4 0.4-0.7 0.4-1.7 0-3 1.3-3 3s1.3 3 3 3h0.2c0.3 0 0.5 0.2 0.6 0.4s0.1 0.5-0.2 0.8c-0.6 0.6-0.9 1.3-0.9 2.1s0.3 1.5 0.9 2.1c0 0 0 0 0 0 1.2 1.2 3.1 1.2 4.3-0.1 0.2-0.2 0.5-0.2 0.8-0.1s0.4 0.3 0.4 0.7c0 1.7 1.3 3 3 3s3-1.3 3-3v-0.2c0-0.3 0.2-0.5 0.4-0.6s0.5-0.1 0.8 0.2c1.2 1.2 3.1 1.2 4.2 0 1.2-1.2 1.2-3.1-0.1-4.3-0.1-0.2-0.2-0.5-0.1-0.7 0 0 0 0 0 0zM18.5 14.6c-0.4 1-0.2 2.1 0.6 3 0.2 0.2 0.3 0.4 0.3 0.7s-0.1 0.5-0.3 0.7c-0.2 0.2-0.4 0.3-0.7 0.3 0 0 0 0 0 0-0.3 0-0.5-0.1-0.8-0.4-0.8-0.8-1.9-1-2.9-0.5-1 0.4-1.6 1.4-1.6 2.4v0.2c0 0.6-0.4 1-1 1s-1-0.4-1-1c0 0 0-0.1 0-0.1 0-1.1-0.7-2-1.7-2.4-0.3-0.2-0.7-0.2-1.1-0.2-0.7 0-1.4 0.3-1.9 0.8-0.4 0.4-1 0.4-1.4 0 0 0 0 0 0 0v0c-0.2-0.2-0.3-0.4-0.3-0.7s0.1-0.5 0.4-0.8c0.8-0.8 1-1.9 0.5-2.9-0.4-1-1.4-1.6-2.4-1.6h-0.2c-0.6 0-1-0.4-1-1s0.4-1 1-1c0 0 0.1 0 0.1 0 1.1 0 2-0.7 2.4-1.7s0.2-2.1-0.6-3c-0.4-0.4-0.4-1 0-1.4s1-0.4 1.5 0.1c0.7 0.7 1.8 0.9 2.7 0.6 0.1 0 0.2 0 0.3-0.1 1-0.4 1.6-1.4 1.6-2.4v-0.2c0-0.6 0.4-1 1-1s1 0.4 1 1.1c0 1.1 0.6 2 1.6 2.4s2.1 0.2 3-0.6c0.2-0.2 0.4-0.3 0.7-0.3v0c0.3 0 0.5 0.1 0.7 0.3 0 0 0 0 0 0 0.4 0.4 0.4 1-0.1 1.5-0.7 0.7-0.9 1.8-0.6 2.7 0 0.1 0 0.2 0.1 0.3 0.4 1 1.4 1.6 2.4 1.6h0.2c0.6 0 1 0.4 1 1s-0.4 1-1.1 1c-1 0-2 0.6-2.4 1.6z'
  ],
  shield: [
    'M12 20.863c-0.38-0.215-0.972-0.57-1.655-1.049-0.679-0.476-1.442-1.068-2.175-1.761-0.849-0.803-1.641-1.725-2.22-2.739-0.079-0.137-0.153-0.276-0.223-0.417-0.248-0.496-0.441-1.009-0.566-1.536-0.104-0.442-0.161-0.895-0.161-1.361v-6.307l7-2.625 7 2.625v6.307c0 0.466-0.057 0.919-0.161 1.361-0.124 0.527-0.317 1.040-0.566 1.536-0.070 0.14-0.145 0.279-0.223 0.417-0.579 1.014-1.371 1.936-2.22 2.739-0.733 0.693-1.495 1.286-2.175 1.761-0.684 0.478-1.275 0.833-1.655 1.049zM12.447 22.894c0.028-0.014 1.041-0.522 2.355-1.442 0.74-0.518 1.583-1.171 2.402-1.947 0.945-0.894 1.878-1.967 2.582-3.2 0.096-0.168 0.188-0.34 0.276-0.515 0.309-0.618 0.559-1.276 0.723-1.971 0.138-0.582 0.215-1.19 0.215-1.819v-7c0-0.426-0.267-0.79-0.649-0.936l-8-3c-0.236-0.089-0.485-0.082-0.702 0l-8 3c-0.399 0.149-0.646 0.527-0.649 0.936v7c0 0.629 0.077 1.237 0.214 1.82 0.164 0.695 0.414 1.353 0.723 1.971 0.087 0.175 0.179 0.346 0.276 0.515 0.704 1.233 1.637 2.306 2.582 3.2 0.82 0.776 1.663 1.429 2.402 1.947 1.314 0.92 2.327 1.428 2.355 1.442 0.292 0.146 0.62 0.136 0.894 0z'
  ]
};

const iconViewBoxes = {
  default: '0 0 24 24',
  exclamation: '0 0 10 28'
};

function Icon(props) {
  const {iconName, className} = props;
  const paths = iconGeometry[iconName] || [];
  const viewBox = iconViewBoxes[iconName] || iconViewBoxes.default;

  return (
    <svg
      aria-hidden="true"
      className={cl('icon', 'icon--' + iconName, className)}
      viewBox={viewBox}>
      {paths.map((geometry, index) => (
        <path key={index} d={geometry} />
      ))}
    </svg>
  );
}

export default memo(Icon);
