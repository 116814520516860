function getPhoneNumber(phone) {
  const phoneNumber = phone.replace(/\D/g, '');
  return phoneNumber.length === 10 ? '1' + phoneNumber : phoneNumber;
}

function getValue(obj, ...props) {
  return props.reduce((target, key) => {
    if (key === undefined) {
      return target;
    }

    return target && target[key];
  }, obj);
}

function isSameDay(date1, date2) {
  if (!date1 || !date2) {
    return false;
  }

  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

function parseDate(obj, prop) {
  const dateString = obj[prop];
  const date = dateString && new Date(dateString);

  if (date && !isNaN(date)) {
    obj[prop] = date;
  }

  return obj;
}

function renderDateTime(value, mode) {
  if (!value) {
    return;
  }

  let format;
  if (mode === 'day') {
    format = {month: 'long', day: 'numeric', weekday: 'long'};
  } else if (mode === 'dateTime') {
    format = {month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};
  } else {
    format = {timeStyle: 'short'};
  }

  return value.toLocaleString(navigator.language || 'en-US', format);
}

function renderEventText(event, language) {
  const {bus_number, event_type, stop_address, stop_description, studentName} = event;
  const stopName = stop_address || stop_description;

  switch (event_type) {
    case 'busArrived':
      return language.translate('eventArrival', bus_number, stopName);
    case 'busMoving':
      return language.translate('eventMoving', bus_number);
    case 'dropoff':
      if (studentName) {
        return language.translate('eventDropoffName', studentName, stopName);
      }

      return language.translate('eventDropoff', stopName);
    case 'pickup':
      if (studentName) {
        return language.translate('eventPickupName', studentName, stopName);
      }

      return language.translate('eventPickup', stopName);
  }
}

export {getPhoneNumber, getValue, isSameDay, parseDate, renderDateTime, renderEventText};
