import React from 'react';
import StudentDetail from './StudentDetail';

export default function StudentList(props) {
  const {language, students} = props;
  if (!students || !students.length) {
    return null;
  }

  return (
    <ul className="list--students">
      {students.map(student => (
        <li key={student.name}>
          <StudentDetail language={language} student={student} />
        </li>
      ))}
    </ul>
  );
}
