export default function className() {
  const classes = [];
  for (let i = 0; i < arguments.length; i++) {
    const name = arguments[i];
    if (!name) {
      continue;
    }

    if (typeof name !== 'object') {
      classes.push(name);
      continue;
    }

    for (let key in name) {
      if (name[key]) {
        classes.push(key);
      }
    }
  }

  return classes.join(' ');
}
