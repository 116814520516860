import Button from '../components/Button';
import React from 'react';
import SettingsForm from '../components/SettingsForm';

export default function SettingsPage(props) {
  const {isVisible, language, reducer} = props;
  return (
    <div aria-hidden={isVisible === false} className="page__body">
      <div className="page__content">
        <div className="block">
          <SettingsForm {...props} />
        </div>
        <Button
          className="button--primary"
          onClick={() => reducer.logout()}
          text={language.translate('logout')}
        />
      </div>
    </div>
  );
}
