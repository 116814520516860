import React from 'react';
import logoImage from '../images/logo.png';

export default function AppErrorPage(props) {
  return (
    <div className="page--centered">
      <div className="content--centered">
        <img src={logoImage} alt="Bus Compass" />
        <div className="error">{props.language.translate('appError')}</div>
      </div>
    </div>
  );
}
