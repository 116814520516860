import {getPhoneNumber, parseDate} from './utility/util';
import {getRequester} from './requests';

function getStatusUrl(id) {
  if (id) {
    return ADMIN_API_ROOT + 'familyStatus/' + id;
  }

  return API_ROOT + 'familystatus';
}

function handleAuthError(e) {
  console.debug(e);
  if (e.status === 403) {
    return {error: 'logout'};
  }
}

function handleError(e) {
  let status = handleAuthError(e);
  if (!status) {
    let error;
    if (e.status === 409) {
      error = 'rateLimit';
    } else if (e.status === 503) {
      error = 'serviceUnavailable';
    } else {
      error = 'unknownError';
    }

    status = {error};
  }

  return status;
}

function handleResponse(onSuccess) {
  return result => {
    const {reason} = result || {};
    return reason ? {error: {text: reason}} : onSuccess(result);
  };
}

function normalizeFamilyStatus(results) {
  if (!results || !results.length) {
    return {events: [], students: []};
  }

  let allEvents = [];
  let students = [];

  results.forEach(student => {
    const {breadcrumbs, last_events, ...rest} = student;
    const events = sortEvents((last_events || []).map(event => parseDate(event, 'event_time')));

    const crumbs = (breadcrumbs || []).map(breadcrumb =>
      parseDate(parseDate(breadcrumb, 'received_time'), 'time')
    );

    const eventHistory = events
      .filter(event => event.event_type !== 'busMoving')
      .map(event => ({...event, studentName: student.name}));

    const studentEvents = [events[0]];
    if (events[0]?.event_type === 'busMoving') {
      studentEvents.push(events.find(event => event.event_type !== 'busMoving'));
    }

    allEvents.push(...eventHistory);
    students.push({...rest, breadcrumbs: crumbs, recentEvents: studentEvents.filter(Boolean)});
  });

  return {
    events: sortEvents(allEvents),
    students: students.sort((s1, s2) => s1.name?.localeCompare(s2.name))
  };
}

function sortEvents(events) {
  return events.sort((e1, e2) => e2.event_time - e1.event_time);
}

export default class AppReducer {
  constructor(options) {
    this.options = options || {};
    const {api, pushNotifications} = this.options;

    this.api = api || getRequester();
    this.platform = pushNotifications;

    this.clearState();
    document.addEventListener('pause', this.stopPolling, false);
    document.addEventListener('resume', this.getStatus, false);
    this.getSettings();
  }

  clearState() {
    this.stopPolling();
    this.state = {
      events: [],
      notifications: [],
      students: []
    };
  }

  getSettings = () => {
    if (this.state.settings) {
      return Promise.resolve();
    }

    return this.api
      .fetch(API_ROOT + 'mobilesetting')
      .then(
        handleResponse(settings => {
          this.state.settings = Object.assign(
            {
              bus_arrived: true,
              bus_moving: true,
              dropoff_swipe: true,
              pickup_swipe: true,
              stop_change: true
            },
            settings
          );

          this.registerForNotifications();
          this.getStatus();
        })
      )
      .catch(handleAuthError)
      .then(this.sendUpdate);
  };

  getStatus = () => {
    if (!this.state.settings) {
      return;
    }

    this.api
      .fetch(getStatusUrl(this.options.id))
      .then(
        handleResponse(result => {
          if (!this.state.settings) {
            return;
          }

          const {events, students} = normalizeFamilyStatus(result);

          this.state.events = events;
          this.state.lastUpdate = Date.now();
          this.state.students = students;

          this.startPolling(5100);
        })
      )
      .catch(e => {
        const authError = handleAuthError(e);

        // If this fails, wait a little bit and try again
        if (!authError) {
          this.startPolling(45000);
        }

        return authError;
      })
      .then(this.sendUpdate);
  };

  handleDeviceToken = device_token => {
    this.hasToken = true;
    this.api
      .post(API_ROOT + 'updatedevice', {
        device_platform: this.options.devicePlatform,
        device_token
      })
      .then(() => this.sendUpdate())
      .catch(console.debug);
  };

  handleNotification = notification => {
    if (!notification) {
      return;
    }

    notification.id = Date.now();
    this.state.notifications = this.state.notifications.concat(notification);
    this.sendUpdate();
  };

  logout() {
    return this.api
      .post(API_ROOT + 'logout')
      .then(() => {
        this.unregister();
        this.api.clearCookies();
        this.sendUpdate();

        if (this.hasToken) {
          location.reload();
        }
      })
      .catch(console.debug);
  }

  onNotificationClosed(notification) {
    let {notifications} = this.state;
    const index = notifications.findIndex(x => x.id === notification.id);

    if (index < 0) {
      return;
    }

    this.state.notifications = notifications.slice();
    this.state.notifications.splice(index, 1);
    this.sendUpdate();
  }

  register({email, phone}) {
    const contact = phone ? getPhoneNumber(phone) : email;
    const phone_or_email = (contact || '').trim();

    if (!phone_or_email) {
      return this.sendUpdate('noContact');
    }

    this.setWorkingForQuery(
      this.api
        .post(API_ROOT + 'register', {phone_or_email})
        .then(handleResponse(() => location.assign('#verify')))
        .catch(handleError)
    );
  }

  registerForNotifications() {
    this.platform.register(this.handleDeviceToken, this.handleNotification);
  }

  saveSettings(name, value) {
    if (!this.state.settings) {
      return Promise.reject(this.translate('settingsError'));
    }

    const oldValue = this.state.settings[name];
    this.updateSettings(name, value);

    return this.api
      .post(API_ROOT + 'updmobilesetting', this.state.settings, 'PUT', 'text')
      .then(() => null)
      .catch(e => {
        console.debug(e);
        this.updateSettings(name, oldValue);
        return this.translate('settingsError');
      });
  }

  sendUpdate = result => {
    let error = result?.error;
    if (error === 'logout') {
      return this.unregister().then(() => this.sendUpdate());
    }

    if (error) {
      error = error.text || this.translate(error);
    }

    let isStale;
    const {lastUpdate, settings} = this.state;

    if (lastUpdate) {
      isStale = lastUpdate + 120000 < Date.now();
    }

    const state = {
      ...this.state,
      deviceType: this.options.deviceType,
      error,
      isLoaded: true,
      isLoading: !lastUpdate,
      isLoggedIn: settings != null,
      isStale,
      showMap: true
    };

    this.platform.hasPermission().then(hasPermission => {
      this.options.onUpdate({...state, hasPermission});
    });
  };

  setWorkingForQuery(query) {
    this.state.isWorking = true;
    this.sendUpdate();

    return query.then(error => {
      this.state.isWorking = false;
      this.sendUpdate(error);
    });
  }

  startPolling(interval) {
    this.stopPolling();
    this.statusTimeout = setTimeout(this.getStatus, interval);
  }

  stopPolling = () => clearTimeout(this.statusTimeout);

  translate = key => this.options.language.translate(key);

  unregister() {
    this.clearState();
    return this.platform.unregister();
  }

  updateSettings(name, value) {
    if (!this.state.settings) {
      return;
    }

    this.state.settings = {...this.state.settings, [name]: value};
    this.sendUpdate();
  }

  verify(passcode) {
    const token = (passcode || '').trim();
    if (!token) {
      return this.sendUpdate('noToken');
    }

    const device_platform = this.options.devicePlatform;
    this.setWorkingForQuery(
      this.api
        .post(API_ROOT + 'verify', {device_platform, token})
        .then(
          handleResponse(() => {
            this.options.id = null;
            return this.getSettings();
          })
        )
        .catch(handleError)
    );
  }
}
