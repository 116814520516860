import {isSameDay, renderDateTime, renderEventText} from '../utility/util';
import React from 'react';

function getBusDistanceText(student, language) {
  const {bus_number, StopsRemaining} = student;
  if (!bus_number) {
    return;
  }

  if (StopsRemaining === 0) {
    return language.translate('busArriving', bus_number);
  }

  if (StopsRemaining > 0) {
    return language.translate(
      'bus',
      bus_number,
      StopsRemaining,
      language.translatePlural(StopsRemaining, 'stops')
    );
  }
}

function getNextStopText(student, language) {
  const {is_drop, stop_address, stop_description} = student;
  const stopName = stop_address || stop_description;

  if (stopName) {
    return language.translate(is_drop ? 'dropoffLocation' : 'pickupLocation', stopName);
  }
}

export default function StudentDetail(props) {
  const {language, student} = props;
  const {is_drop, name, recentEvents} = student;

  const details = [
    getNextStopText(student, language),
    getBusDistanceText(student, language)
  ].filter(Boolean);

  return (
    <>
      <div className="flex-grid--baseline">
        <h2 className="flex-align--left">{name}</h2>
        <div className="text--aside">{language.translate(is_drop ? 'onBus' : 'offBus')}</div>
      </div>
      <ul className="list--student-detail">
        {details.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
        {recentEvents.map((event, index) => {
          const dateMode = isSameDay(event.event_time, new Date()) ? 'time' : 'dateTime';
          return (
            <li key={'event' + index} className="event">
              {renderEventText(event, language)}
              <span className="event-time">{renderDateTime(event.event_time, dateMode)}</span>
            </li>
          );
        })}
      </ul>
    </>
  );
}
