import Field from '../hoc/field';
import React from 'react';

function applyPattern(pattern, value, length) {
  let patternEnd = 0;
  for (let i = 0; i < length; i++) {
    patternEnd = pattern.indexOf('#', patternEnd) + 1;
  }

  let index = 0;
  return pattern.slice(0, patternEnd).replace(/#/g, () => value[index++]);
}

function handleInput(onChange, mask) {
  return e => {
    let {id, value} = e.target;
    if (mask === 'numeric' && value.length) {
      value = value.replace(/\D/g, '');
      value = applyPattern('(###) ###-####', value, Math.min(value.length, 10));
    }

    onChange(id, value);
  };
}

function InputField(props) {
  const {mask, label, onChange, type, usePlaceholder, value, ...rest} = props;
  return (
    <input
      aria-label={label}
      onChange={handleInput(onChange, mask)}
      placeholder={usePlaceholder && label}
      type={type || 'text'}
      value={value != null ? value : ''}
      {...rest}
    />
  );
}

export default Field(InputField);
