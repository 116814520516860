import LeafletMap from '../controls/LeafletMap';
import React, {Component} from 'react';
import SelectField from '../components/SelectField';
import cl from '../utility/className';

function getRouteByMessage(routes, message) {
  return routes.find(route => route.message === message) || routes[0];
}

function normalizeLocation(location) {
  if (!location || location.fix === false || !location.latitude || !location.longitude) {
    return;
  }

  const {Course, latitude, longitude} = location;
  return {
    course: Course,
    lat: latitude,
    lng: longitude
  };
}

let mapId = 1;
export default class MapPage extends Component {
  state = {routes: []};

  constructor(props) {
    super(props);

    this.mapId = 'map-' + mapId++;
    this.state = this.calculateState(props);
  }

  componentDidMount() {
    this.map = new LeafletMap(this.mapId);
    this.updateMapState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.students !== prevProps.students) {
      this.setState(this.calculateState(this.props, this.state.route), () => this.updateMapState());
    }
  }

  componentWillUnmount() {
    this.map.destroy();
  }

  calculateState(props, currentRoute) {
    const {language, students} = props;
    const routes = students
      .filter(student => student.breadcrumbs?.length)
      .map(student => {
        const {breadcrumbs, bus_number, is_drop, name, stop_latitude, stop_longitude} = student;
        return {
          bus: normalizeLocation(breadcrumbs[0]),
          message: language.translate(is_drop ? 'routeDropoff' : 'routePickup', bus_number, name),
          stop: normalizeLocation({latitude: stop_latitude, longitude: stop_longitude})
        };
      });

    let routeOptions;
    if (routes.length > 1) {
      routeOptions = routes.map(({message}) => ({id: message, name: message}));
    }

    const route = getRouteByMessage(routes, currentRoute?.message);
    return {route, routes, routeOptions};
  }

  onRouteChange = (name, value) => {
    const route = getRouteByMessage(this.state.routes, value);
    this.setState({route}, () => this.updateMapState());
  };

  updateMapState() {
    const {route} = this.state;
    this.map.setRoute(route);

    if (!route) {
      this.map.showDefaultLocation();
    }
  }

  render() {
    const {route, routeOptions} = this.state;
    const {ConnectionWarning, isMobile, isVisible, language} = this.props;
    const message = !routeOptions && route?.message;

    return (
      <div aria-hidden={isVisible === false} className={cl('page--map', {'map--empty': !route})}>
        <div id={this.mapId} className="map" />
        {isMobile && (
          <>
            {routeOptions && (
              <SelectField
                inline
                data={routeOptions}
                language={language}
                onChange={this.onRouteChange}
                value={route.message}
              />
            )}
            <div className="map__message-container">
              <ConnectionWarning />
              {!route && <p className="map__message">{language.translate('noActiveBuses')}</p>}
              {message && <p className="map__message">{message}</p>}
            </div>
          </>
        )}
      </div>
    );
  }
}
