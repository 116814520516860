import ActivityPage from './ActivityPage';
import MapPage from './MapPage';
import NavButton from '../components/NavButton';
import OverviewPage from './OverviewPage';
import React, {PureComponent} from 'react';
import SettingsPage from './SettingsPage';
import logoReverse from '../images/logo-reverse.png';

export default class MobileShell extends PureComponent {
  constructor(props) {
    super(props);

    this.pages = ['overview', 'activity', 'settings'];
    if (props.showMap) {
      this.pages.unshift('map');
    }

    this.state = {swipeDistance: 0};
    this.maxOffset = (this.pages.length - 1) * 100;
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.onTouchStart, false);
    window.addEventListener('touchmove', this.onTouchMove, false);
    window.addEventListener('touchcancel', this.resetSwipe, false);
    window.addEventListener('touchend', this.onTouchEnd, false);
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.onTouchStart, false);
    window.removeEventListener('touchmove', this.onTouchMove, false);
    window.removeEventListener('touchcancel', this.resetSwipe, false);
    window.removeEventListener('touchend', this.onTouchEnd, false);
  }

  getActiveTouch(e) {
    const {touches} = e || {};
    if (!touches || touches.length !== 1) {
      return;
    }

    const {pageX, pageY} = touches[0];
    return {pageX, pageY, timeStamp: e.timeStamp};
  }

  getPageIndex() {
    const index = this.pages.indexOf(this.props.hash);
    return index < 0 ? 1 : index;
  }

  getPageOffset() {
    const {swipeDistance} = this.state;
    let offset = this.getPageIndex() * -100;

    if (swipeDistance) {
      offset += (swipeDistance / window.innerWidth) * 100;
    }

    return Math.max(-this.maxOffset, Math.min(0, offset));
  }

  onTouchStart = e => {
    this.startTouch = this.getActiveTouch(e);
  };

  onTouchMove = e => {
    const touch = this.getActiveTouch(e);
    if (!this.startTouch || !touch) {
      return;
    }

    const deltaX = touch.pageX - this.startTouch.pageX;

    const xDist = Math.abs(deltaX);
    const yDist = Math.abs(touch.pageY - this.startTouch.pageY);

    if (xDist && yDist && xDist / yDist < 1.5) {
      return this.resetSwipe();
    }

    let swipeDistance = 0;
    if (xDist > 50 && xDist > yDist * 1.5) {
      swipeDistance = deltaX;
    }

    this.setState({swipeDistance});
  };

  onTouchEnd = e => {
    const {swipeDistance} = this.state;
    if (this.startTouch && swipeDistance) {
      const swipePercent = swipeDistance / window.innerWidth;
      const swipeTime = Math.abs(this.startTouch.timeStamp - e.timeStamp);

      if (swipeTime < this.maxOffset || Math.abs(swipePercent) > 0.4) {
        const index = this.getPageIndex();
        const hash = this.pages[index + (swipePercent > 0 ? -1 : 1)];

        if (hash) {
          location.replace('#' + hash);
        }
      }
    }

    this.resetSwipe();
  };

  resetSwipe = () => {
    this.startTouch = null;
    this.setState({swipeDistance: 0});
  };

  render() {
    const {hash, language, notifications, showMap} = this.props;
    const scroll = this.getPageOffset();

    return (
      <>
        <header>
          <img src={logoReverse} alt="Bus Compass" />
        </header>
        <main className="page--swipeable" style={{transform: `translateX(${scroll}%)`}}>
          {showMap && <MapPage isVisible={hash !== 'map'} {...this.props} />}
          <OverviewPage isVisible={hash !== 'overview'} {...this.props} />
          <ActivityPage isVisible={hash !== 'activity'} {...this.props} />
          <SettingsPage isVisible={hash !== 'settings'} {...this.props} />
        </main>
        <footer>
          <nav>
            {showMap && <NavButton name="map" title={language.translate('map')} />}
            <NavButton
              name="overview"
              icon="people"
              hasNotifications={notifications.length > 0}
              title={language.translate('overview')}
            />
            <NavButton name="activity" icon="calendar" title={language.translate('activity')} />
            <NavButton name="settings" title={language.translate('settings')} />
          </nav>
        </footer>
      </>
    );
  }
}
