import './styles.css';
import {render} from 'react-dom';
import App from './App';
import React from 'react';

let bootstrap;
if (WEB_BUILD) {
  bootstrap = {
    init: () => require('./web/polyfills'),
    register: () => ({
      devicePlatform: 'web',
      deviceType: 'web',
      pushNotifications: require('./notifications/firebase').default
    })
  };
} else {
  bootstrap = {
    init: () => {},
    register: () => ({
      devicePlatform: window.cordova?.platformId || 'android',
      deviceType: 'app',
      pushNotifications: require('./notifications/cordova').default
    })
  };
}

function onDeviceReady() {
  startApp();
  if (navigator.splashscreen) {
    navigator.splashscreen.hide();
  }
}

function onReady(callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback, false);
  }
}

function startApp() {
  render(<App {...bootstrap.register()} />, document.querySelector('[data-react-root]'));
}

try {
  bootstrap.init();
  if (window.cordova) {
    document.addEventListener('deviceready', onDeviceReady, false);
  } else {
    onReady(startApp);
  }

  window.VERSION = VERSION;
} catch (e) {
  console.log(e);
}
