import Button from '../components/Button';
import EventHistory from '../components/EventHistory';
import MapPage from './MapPage';
import NotificationList from '../components/NotificationList';
import React from 'react';
import SettingsForm from '../components/SettingsForm';
import StudentList from '../components/StudentList';
import cl from '../utility/className';
import logoReverse from '../images/logo-reverse.png';

function groupStudents(students) {
  const mapGroups = [];
  const studentsWithoutMap = [];

  students.forEach(student => {
    const {breadcrumbs, bus_id, stop_id} = student;
    if (!breadcrumbs.length || !bus_id || !stop_id) {
      studentsWithoutMap.push(student);
      return;
    }

    const groupId = stop_id + '_' + bus_id;
    const group = mapGroups.find(group => group.id === groupId);

    if (group) {
      group.students.push(student);
    } else {
      mapGroups.push({id: groupId, students: [student]});
    }
  });

  return {mapGroups, studentsWithoutMap};
}

const Card = ({children, className}) => <div className={cl('card', className)}>{children}</div>;

function renderStudents(props) {
  const {isLoading, language, students} = props;
  if (!students.length) {
    return (
      <Card>
        <p className={cl('card__header', {'loading-indicator': isLoading})}>
          {language.translate(isLoading ? 'loading' : 'noStudents')}
        </p>
      </Card>
    );
  }

  const {mapGroups, studentsWithoutMap} = groupStudents(students);
  return (
    <>
      {mapGroups.map(group => (
        <Card key={group.id} className="card--map">
          <MapPage language={language} students={group.students} />
          <StudentList language={language} students={group.students} />
        </Card>
      ))}
      {studentsWithoutMap.length > 0 && (
        <Card>
          <StudentList language={language} students={studentsWithoutMap} />
        </Card>
      )}
    </>
  );
}

export default function DesktopShell(props) {
  const {ConnectionWarning, events, language, notifications, reducer, students} = props;
  return (
    <div className="page--grid">
      <header>
        <img src={logoReverse} alt="Bus Compass" />
        <Button
          className="header__button button--ghost"
          onClick={() => reducer.logout()}
          text={language.translate('logout')}
        />
      </header>
      <div className="card-container">
        <div className="alert--offline">
          <ConnectionWarning />
        </div>
        {renderStudents(props)}
        {events.length > 0 && (
          <Card className="card--padded">
            <EventHistory events={events} language={language} />
          </Card>
        )}
        {notifications.length > 0 && (
          <Card className="card--padded">
            <NotificationList language={language} notifications={notifications} reducer={reducer} />
          </Card>
        )}
        <Card className="card--padded">
          <SettingsForm {...props} />
        </Card>
      </div>
    </div>
  );
}
