import React, {PureComponent} from 'react';
import cl from '../utility/className';

export default function field(WrappedComponent, options) {
  const {hasTranslations, noContainer, useFieldset} = options || {};
  const Container = useFieldset ? 'fieldset' : 'div';
  const Label = useFieldset ? 'legend' : 'label';

  class Field extends PureComponent {
    render() {
      const {className, id, inline, language, translate, ...fieldProps} = this.props;
      fieldProps.id = id || fieldProps.name;

      let labelText;
      if (hasTranslations) {
        fieldProps.translate = language.getTranslator(fieldProps.id);
        labelText = fieldProps.translate('title');
      } else {
        labelText = translate(fieldProps.id);
      }

      if (inline || noContainer) {
        return <WrappedComponent className={className} label={labelText} {...fieldProps} />;
      }

      return (
        <Container className={cl('field', className)}>
          <Label htmlFor={fieldProps.id}>{labelText}</Label>
          <WrappedComponent {...fieldProps} />
        </Container>
      );
    }
  }

  Field.displayName = 'Field';
  return Field;
}
