export default {
  activity: 'Activity',
  appError:
    'An error occurred. If you continue having issues, please contact your system administrator.',
  back: 'Go back',
  bus: 'Bus {0} is {1} {2} away',
  busArriving: 'Bus {0} is arriving',
  cancel: 'Cancel',
  close: 'Close',
  codeNoteReceived: "I didn't received a passcode",
  contactType: {
    email: 'Email',
    phoneNumber: 'Phone'
  },
  dropoffLocation: 'Expected drop off at {0}',
  eventArrival: 'Bus {0} arrived at {1}',
  eventMoving: 'Bus {0} started moving',
  eventDropoff: 'Dropped off at {0}',
  eventDropoffName: 'Dropped off {0} at {1}',
  eventPickup: 'Picked up at {0}',
  eventPickupName: 'Picked up {0} at {1}',
  loading: 'Loading student information . . .',
  login: 'Log in',
  loginForm: {
    email: 'Email address',
    passcode: 'Passcode',
    phone: 'Phone number'
  },
  logout: 'Logout',
  offline: 'You are offline . . .',
  pickupLocation: 'Expected pick up at {0}',
  map: 'Map',
  noActivity: 'No recent activity. We will notify you when we have more information.',
  noActiveBuses: "You'll be able to see your bus once it gets closer to your stop",
  noContact: 'Enter the phone number or email address on record at your school.',
  noStudents: 'No information available. We will notify you when we have more information.',
  noToken: 'Enter your 6 digit passcode.',
  notificationPermission: 'Notifications are disabled',
  notificationPermissionApp: 'Enable push notifications to receive updates when the app is closed.',
  notificationPermissionBrowser: 'Allow notifications to receive updates when the page is closed.',
  notificationSettingsHeader: 'Notify me when',
  notificationsHeader: 'Notifications',
  offBus: 'Off bus',
  onBus: 'On bus',
  overview: 'Overview',
  pluralCardinalRules: {
    stops: {
      one: 'stop',
      other: 'stops'
    }
  },
  rateLimit: 'Rate limit exceeded. Please wait and try again.',
  recentActivity: 'Recent activity',
  registerNotice: 'Log in using the email address or phone number on record at your school.',
  routeDropoff: 'Bus {0} en route to drop off {1}',
  routePickup: 'Bus {0} en route to pick up {1}',
  serviceUnavailable: 'Our servers are currently busy. Please try again in a minute.',
  settings: 'Settings',
  settingsError: 'Unable to save settings',
  settingsForm: {
    bus_arrived: 'The bus arrives',
    bus_moving: 'The bus starts moving',
    dropoff_swipe: 'Someone is dropped off',
    pickup_swipe: 'Someone is picked up',
    stop_change: 'The bus gets closer'
  },
  stale: 'Connection lost. Information may be out of date.',
  unknownError: 'Something went wrong. Please try again.',
  verifyEmail:
    "We're sending you an email with a 6 digit passcode. Enter the passcode below to log in.",
  verifyPhone:
    "We're sending you a text with a 6 digit passcode. Enter the passcode below to log in.",
  verifyInfo1:
    'The passcode may take a few minutes to arrive. If you entered an email, please check your spam folder.',
  verifyInfo2:
    "If you don't receive a passcode, make sure you're using the email address or phone number on record at your school."
};
